import moment from "moment";
import { useCallback, useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import Dropdown from "../components/dropdown/dropdown";
import Source from "../constants/source";
import { ETheme } from "../constants/theme";
import { ThemeContext } from "../contexts/theme-context";
import { TicketContext } from "../contexts/ticket-context";
import { setUrl } from "../utils/localStorage";
import Content from "./content/content";
import Header from "./header/header";
import "./style.scss";
import {
  defaultEvents,
  EventContext,
  EventTypeParam,
} from "../contexts/event-context";

export default function Main() {
  const { theme } = useContext(ThemeContext);
  const {
    getTicketByUrl: ctxGetTicketByUrl,
    event,
    styleCustom,
    termconditionUrl,
  } = useContext(TicketContext);
  const { onGetEvents, loadedEvents, setLoadedEvents } =
    useContext(EventContext);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const paramsAsObject = Object.fromEntries([...(searchParams as any)]);
    const path = location.pathname.split("/");
    if (!paramsAsObject.transactionId) {
      if (path[1]) {
        getTicketByUrl();
      } else {
        fetchEvents();
      }
    } else {
      const url = `${window.location.origin}${location.pathname}`;
      if (url) ctxGetTicketByUrl(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const getFullUrl = () => {
    let url = window.location.href;
    const obj = new URL(url);
    obj.search = "";
    obj.hash = "";
    url = obj.toString();
    const lastString = url.slice(-1);
    if (lastString === "/") url = url.substring(0, url.length - 1);
    return url;
  };

  const getTicketByUrl = async () => {
    const url = getFullUrl();
    setUrl(url);
    try {
      await ctxGetTicketByUrl(url);
    } catch (error: any) {
      if (error.response?.data.Error === "Event not found ") {
        await fetchEvents();
      }
    }
  };

  const fetchEvents = async () => {
    await onGetEvents({
      date: EventTypeParam.upcoming,
      limit: defaultEvents.limit,
      page: defaultEvents.page,
    });
    if (setLoadedEvents) setLoadedEvents(true);
  };

  const onOpenTermConditions = useCallback(() => {
    const linkURL = document.createElement("a");
    linkURL.href = Source.termConditional;
    linkURL.setAttribute("target", "_blank");
    document.body.appendChild(linkURL);
    linkURL.click();
  }, []);

  return (
    <>
      {event._id && (
        <Helmet>
          <meta charSet="utf-8" />
          <link rel="canonical" href="%PUBLIC_URL%/favicon.ico" />
          <meta
            name="description"
            content={`Ticket sale for ${event.name} on ${moment(
              event.startDate
            ).format("MMMM DD, YYYY")} ${t("powered")} EventCHI`}
          />
        </Helmet>
      )}
      {(event._id || loadedEvents) && (
        <>
          <Header />
          <Content />
          <div className="footer">
            <div className="termsCondition">
              <div
                className={`powered ${theme === ETheme.DARK && "powered-dark"}`}
              >
                <a
                  href={
                    termconditionUrl
                      ? termconditionUrl
                      : "https://cdn.chi.app/termcondition.pdf"
                  }
                  target="blank"
                  style={{
                    textDecoration: "none",
                    color: styleCustom?.primaryColor
                      ? styleCustom.primaryColor
                      : `var(--primary)`,
                  }}
                >
                  Terms & conditions
                </a>
              </div>
              <div
                className={`powered ${theme === ETheme.DARK && "powered-dark"}`}
                onClick={() => onOpenTermConditions()}
                style={{
                  color: styleCustom?.secondaryColor
                    ? styleCustom.secondaryColor
                    : `var(--white)`,
                }}
              >
                {t("powered")}
                <span
                  style={{
                    color: styleCustom?.primaryColor
                      ? styleCustom.primaryColor
                      : `var(--primary)`,
                  }}
                >
                  {`  EventCHI`}
                </span>
              </div>
            </div>
            {/* <div
              className={`powered ${theme === ETheme.DARK && "powered-dark"}`}
              onClick={() => onOpenTermConditions()}
            >
              {t("powered")} <span>EventCHI</span>
            </div> */}
            <Dropdown />
          </div>
        </>
      )}
    </>
  );
}
