import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { EChangeCountTicket, ITicket } from "../../apis/types";
import { ETheme } from "../../constants/theme";
import { ThemeContext } from "../../contexts/theme-context";
import { TicketContext } from "../../contexts/ticket-context";
import { fixDecimals } from "../../utils/helper";
import "./item-ticket.scss";
import parse from "html-react-parser";

export interface IItemTicketProps {
  ticket: ITicket;
}

export default function ItemTicket(props: IItemTicketProps) {
  const { ticket } = props;
  const { theme } = useContext(ThemeContext);
  const { styleCustom } = useContext(TicketContext);
  const { handleChangeCountTicket, currency, event } =
    useContext(TicketContext);
  const { t } = useTranslation();

  const ticketSoldOut = useMemo(() => {
    return ticket.numOfLefTickets == 0;
  }, [ticket]);

  const renderPriceTicket = useMemo(() => {
    return (
      <div className="price-ticket">{`${currency} ${
        ticket.price &&
        fixDecimals(
          ticket.price +
            (event.includeVatToTicketPrice
              ? ticket.vat
                ? ticket.price * ticket.vat
                : ticket.price * event.vatOfTicket
              : 0)
        )
      }`}</div>
    );
  }, [ticket]);

  return (
    <div className="item-ticket" style={{backgroundColor: styleCustom?.secondaryColor && styleCustom?.secondaryColor, color: styleCustom?.tertiaryColor ? styleCustom?.tertiaryColor : `var(text--primary)` }}>
      <div className="top-content">
        {ticketSoldOut && (
          <div className="ticket-status-section">{t("soldOut")}</div>
        )}
        <div
          className={`ticketName ${
            theme !== ETheme.DARK && "ticketName-theme"
          }`}
        >
          {ticket.name}
        </div>
      </div>
      {ticket.description && (
        <div className="middle-content">
          <div
            className={`description ${
              theme !== ETheme.DARK && "description-light"
            }`}
          >
            {parse(ticket.description)}
          </div>
        </div>
      )}
      <div className="bottom-content">
        {!ticketSoldOut && (
          <>
            {renderPriceTicket}
            <div className="right">
              <div className="wrapper-btn">
                <div
                  className={`btn-chi`}
                  style={{backgroundColor: styleCustom?.primaryColor ? styleCustom?.primaryColor : `var(--primary)`}}
                  onClick={() =>
                    handleChangeCountTicket(
                      ticket._id,
                      EChangeCountTicket.DECREMENT,
                      undefined
                    )
                  }
                >
                  ─
                </div>
              </div>
              <div>{ticket.count}</div>
              <div className="wrapper-btn">
                <div
                  className={`btn-chi`}
                  style={{backgroundColor: styleCustom?.primaryColor ? styleCustom?.primaryColor : `var(--primary)`}}
                  onClick={() =>
                    handleChangeCountTicket(
                      ticket._id,
                      EChangeCountTicket.INCREMENT,
                      undefined
                    )
                  }
                >
                  ✛
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
