import { createGlobalStyle } from "styled-components";
import FontLatoChi from "../assets/fonts/LatoCHI-Regular.ttf";
import FontBabes from "../assets/fonts/BebasNeue-Regular.ttf";
import FontCourier from "../assets/fonts/CourierPrime-Regular.ttf";
import Gasolyn from "../assets/fonts/Gasolyn-Stamp.ttf";
import LouisCafe from "../assets/fonts/Louis-George-Cafe.ttf";
import Arial from "../assets/fonts/Arial.ttf";
import Helvetica from "../assets/fonts/Helvetica.ttf";
import Lato from "../assets/fonts/Lato.ttf";
import Merriweather from "../assets/fonts/Merriweather.ttf";
import Montserrat from "../assets/fonts/Montserrat.ttf";
import NotoSans from "../assets/fonts/NotoSans.ttf";
import OpenSans from "../assets/fonts/OpenSans.ttf";
import Oswald from "../assets/fonts/Oswald.ttf";
import PlayfairDisplay from "../assets/fonts/PlayfairDisplay.ttf";
import PTSans from "../assets/fonts/PTSans.ttf";
import Raleway from "../assets/fonts/Raleway.ttf";
import Roboto from "../assets/fonts/Roboto.ttf";
import Tahoma from "../assets/fonts/Tahoma.ttf";
import TimesNewRoman from "../assets/fonts/TimesNewRoman.ttf";
import TrebuchetMS from "../assets/fonts/TrebuchetMS.ttf";
import Ubuntu from "../assets/fonts/Ubuntu.ttf";
import Verdana from "../assets/fonts/Verdana.ttf";
import { ETheme } from "../constants/theme";
import { getFontFamilyTheme } from "../constants/themeGlobal";
export const GlobalStyle = createGlobalStyle<{
  theme: ETheme;
  styleCustom: any;
}>`
  * {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
  }
  body {
    font-family: ${(props) =>
      props?.styleCustom?.fontSubTitle
        ? props.styleCustom.fontSubTitle
        : props.theme === ETheme.DARK
        ? "Lato"
        : getFontFamilyTheme(props.theme)};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @font-face {
    font-family: 'arial';
    src: url(${Arial}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'helvetica';
    src: url(${Helvetica}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'lato';
    src: url(${Lato}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'merriweather';
    src: url(${Merriweather}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'montserrat';
    src: url(${Montserrat}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'noto sans';
    src: url(${NotoSans}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'open sans';
    src: url(${OpenSans}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'oswald';
    src: url(${Oswald}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'playfair display';
    src: url(${PlayfairDisplay}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'pt sans';
    src: url(${PTSans}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'raleway';
    src: url(${Raleway}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'roboto';
    src: url(${Roboto}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'tahoma';
    src: url(${Tahoma}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'times new roman';
    src: url(${TimesNewRoman}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'trebuchet ms';
    src: url(${TrebuchetMS}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'ubuntu';
    src: url(${Ubuntu}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'verdana';
    src: url(${Verdana}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${FontLatoChi}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'Bebas Neue';
    src: url(${FontBabes}) format('truetype');
    font-style: normal;
  }  
  @font-face {
    font-family: 'Courier Prime';
    src: url(${FontCourier}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'Gasolyn';
    src: url(${Gasolyn}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'LouisCafe';
    src: url(${LouisCafe}) format('truetype');
    font-style: normal;
  }
`;
