import React, { PropsWithChildren, useContext, useEffect } from "react";
import "./layout.scss";
import { EventContext } from "../contexts/event-context";
import { TicketContext } from "../contexts/ticket-context";

export interface ILayoutProps {}

type Props = PropsWithChildren<ILayoutProps>;

export default function Layout(props: Props) {
  const { event, styleCustom } = useContext(TicketContext);
  const { loadedEvents } = useContext(EventContext);

  return (
    <div className="layout">
      <div
        className={`layout-container ${
          event._id || loadedEvents ? "layout-border" : ""
        }`}
        style={{
          backgroundImage:
            styleCustom?.widgetImage && `url(${styleCustom?.widgetImage})`,
          backgroundColor: styleCustom?.tertiaryColor
            ? `${styleCustom?.tertiaryColor}`
            : "",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
