import React from "react";
import { Transition } from "react-transition-group";
import { getSafePercent } from "../../../utils/helper";
import { transitions } from "./transitions";
import "../process-bar.scss";

export interface StepProps {
  accomplished: boolean;
  position: number;
  index: number;
  children: (props: {
    accomplished: boolean;
    transitionState: string;
    index: number;
    position: number;
  }) => React.ReactNode;
  transition?: "scale" | "rotate" | "skew";
  transitionDuration?: number;
  onClick?: () => void;
}

export const Step: React.FC<StepProps> = ({
  accomplished,
  position,
  index,
  children,
  transition = null,
  transitionDuration = 300,
  onClick,
}) => {
  const safePosition = getSafePercent(position);

  return (
    <Transition in={accomplished} timeout={transitionDuration}>
      {(state) => {
        // Calculate style
        let style: React.CSSProperties = {
          left: `${safePosition}%`,
          transitionDuration: `${transitionDuration}ms`,
        };

        // Apply transition styles if transition is specified
        if (transition) {
          style = {
            ...style,
            ...transitions[transition][state],
          };
        }

        return (
          <div className="RSPBstep" style={style} onClick={onClick}>
            {children({
              accomplished,
              position: safePosition,
              transitionState: state,
              index,
            })}
          </div>
        );
      }}
    </Transition>
  );
};  