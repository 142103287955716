import React from "react";
import { getSafePercent, getStepPosition } from "../../../utils/helper";
import { Step } from "../step";
import "../process-bar.scss";

type ProgressBarProps = {
  percent: number;
  children: React.ReactElement<typeof Step>[];
  stepPositions?: Array<number>;
  unfilledBackground?: string;
  filledBackground?: string;
  width?: number;
  height?: number;
  hasStepZero?: boolean;
  text?: string;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({
  percent,
  children,
  stepPositions = [],
  unfilledBackground,
  filledBackground,
  width,
  height,
  hasStepZero = true,
  text = null,
}) => {
  const safePercent = getSafePercent(percent);

  return (
    <div
      className="RSPBprogressBar"
      style={{ 
        background: unfilledBackground, 
        width, 
        height 
      }}
    >
      {React.Children.map(children, (step, index) => {
        const position =
          stepPositions.length > 0
            ? stepPositions[index]
            : getStepPosition(
                React.Children.count(children),
                index,
                hasStepZero
              );
        
        return React.cloneElement(step as React.ReactElement<any>, {
          accomplished: true,
          position,
          index,
        });
      })}

      {text && <div className="RSPBprogressBarText">{text}</div>}

      <div
        className="RSPBprogression"
        style={{
          background: filledBackground,
          width: `${safePercent}%`,
        }}
      />
    </div>
  );
};